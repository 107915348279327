<template>
    <div>
    <nav class="navbar navbar-light bg-light">
        <div id="logo">
                <div class="row align-items-center">
                        <inertia-link href="/">
                            <LogoClinica />
                        </inertia-link>
                    <div v-if="servicio == 1" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Ticket Web Electrónico Laboratorio Clínico</a>
                    </div>
                    <div v-if="servicio == 2" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Ticket Web Electrónico Servicio de Imágenes </a>
                    </div>
                    <div v-if="servicio == 3" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Ticket Web Electrónico Servicio de Endoscopía </a>
                    </div>
                    <div v-if="servicio == 4" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Ticket Web Electrónico Servicio de Cardiología </a>
                    </div>
                    <div v-if="servicio == 5" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Ticket Web Electrónico Servicio de Hemato - Oncología </a>
                    </div>
                    <div v-if="servicio == 6" id="tit" class="col-10 col-md-7 text-left">
                    <a href="/"> Otros Servicios  </a>
                    </div>
                </div>
        </div>
    </nav>
        <div v-if="!loading_table" class="row m-0 justify-content-center align-items-center vh-100">
            <div class="col text-center">
                <div class="spinner-border spinner-alemana" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="loading_table" class="container">
                <div class="row justify-content-right mt-6">
                    <div class="col col-md-10">
                        <button data-toggle="modal" data-target="#modalConsultar" class="btn button-blue-alemana" v-on:click="borrar()" >Consultar Reserva</button>
                    </div>
                    <div class="col col-md-2 text-center">
                        <a href="javascript:location.reload()"><i class="fas fa-sync"></i><br>Actualizar Agenda</a>
                    </div>
                </div>

            <div class="row justify-content-md-center mt-4">
            <div class="col col-md-4">
                <input type="date" v-on:change="cambiarfecha()" v-model="selectweek" name="semana" id="semana" class="form-control" :min="datefilter_start" :max="datefilter_end">
            </div>
        </div>
        <div class="row justify-content-md-center mt-2">
            <div class="col col-md-8 text-center">{{ selectweek == '' ? 'Seleccione una semana' : 'Semana del '+ diasemana }}</div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col text-center">
                <span class="badge badge-pill badge-light">Boxs disponibles</span>
                <span class="badge badge-pill disp-cupo">Boxs Limitados</span>
                <span class="badge badge-pill no-cupo">Boxs no Disponibles</span>
            </div>
        </div>
            <div v-if="selectweek != ''" class="row mt-2 scroll-test tableFixHead">
                <div class="col">
                    <table class="table table-bordered table-striped">
                        <thead class="text-center">
                            <tr>
                                <th class="color-table-alemana" scope="col">Hora</th>
                                <th :class="close_lu ? 'color-red-alemana' : 'color-table-alemana'" scope="col">LUNES<div class="text-small">{{ showdiatitletable(8) }}</div></th>
                                <th :class="close_ma ? 'color-red-alemana' : 'color-table-alemana'" scope="col">MARTES<div class="text-small">{{ showdiatitletable(9) }}</div></th>
                                <th :class="close_mi ? 'color-red-alemana' : 'color-table-alemana'" scope="col">MIERCOLES<div class="text-small">{{ showdiatitletable(10) }}</div></th>
                                <th :class="close_ju ? 'color-red-alemana' : 'color-table-alemana'" scope="col">JUEVES<div class="text-small">{{ showdiatitletable(11) }}</div></th>
                                <th :class="close_vi ? 'color-red-alemana' : 'color-table-alemana'" scope="col">VIERNES<div class="text-small">{{ showdiatitletable(12) }}</div></th>
                                <th :class="close_sa ? 'color-red-alemana' : 'color-table-alemana'" scope="col">SABADO<div class="text-small">{{ showdiatitletable(13) }}</div></th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr v-for="(data, n) in dataschedule" :key="n">
                                <td>{{ data.time }}</td>
                                <td :class="close_lu ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.mo.color" data-toggle="modal" :data-target="data.mo.bloqmodal || close_lu ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.mo.row,data.mo.col,data.time,data.mo.exist)">{{ data.mo.value }}</td>
                                <td :class="close_ma ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.tu.color" data-toggle="modal" :data-target="data.tu.bloqmodal || close_ma ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.tu.row,data.tu.col,data.time,data.tu.exist)">{{ data.tu.value }}</td>
                                <td :class="close_mi ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.we.color" data-toggle="modal" :data-target="data.we.bloqmodal || close_mi ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.we.row,data.we.col,data.time,data.we.exist)">{{ data.we.value }}</td>
                                <td :class="close_ju ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.th.color" data-toggle="modal" :data-target="data.th.bloqmodal || close_ju ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.th.row,data.th.col,data.time,data.th.exist)">{{ data.th.value }}</td>
                                <td :class="close_vi ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.fr.color" data-toggle="modal" :data-target="data.fr.bloqmodal || close_vi ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.fr.row,data.fr.col,data.time,data.fr.exist)">{{ data.fr.value }}</td>
                                <td :class="close_sa ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.sa.color" data-toggle="modal" :data-target="data.sa.bloqmodal || close_sa ? '' : '#modalAdd'" v-on:click.prevent="showmodalagend(data.sa.row,data.sa.col,data.time,data.sa.exist)">{{ data.sa.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="footer-copyright text-center py-3">© {{ year() }} Copyright:
                        <a id="copyright" href="https://www.clinicaalemanavaldivia.cl/">Clinica Alemana de Valdivia</a>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modalAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Reservar hora</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-alemana" role="alert" v-if="errors.length">
                            <button type="button" class="close" v-on:click.prevent="errors = []" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <b>Por favor corriga los siguientes errores:</b>
                            <ul>
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label id="txt-rut" for="txt-rut"> {{ txt_rut }} </label>
                                    <input type="text" v-model="rut" class="form-control upper" id="in-rut" max="10">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="txt-nombre">Nombre</label>
                                    <input type="text" v-model="name" v-on:click="check()" class="form-control upper" id="in-nombre">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="txt-direccion">Dirección</label>
                                    <input type="text" v-model="address" class="form-control upper" id="in-direccion">
                                </div>
                            </div>            
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label id="txt-celular" for="txt-celular">{{ txt_celular }}</label>
                                    <input type="text" v-model="telephone" class="form-control" id="in-celular">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="form-group">
                                    <label for="txt-correo">Correo</label>
                                    <input type="email" v-model="email" v-on:click="isnumber()" class="form-control" id="in-correo">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md">
                                <div class="form-group">
                                    <label for="txt-dia">Dia</label>
                                    <input disabled type="text" v-model="fecha_modal" class="form-control" id="in-dia">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md">
                                <div class="form-group">
                                    <label for="txt-hora">Hora</label>
                                    <input disabled type="text" v-model="hora_modal" class="form-control" id="in-hora">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="alert alert-warning" role="alert">
                                    Esto solo corresponde a un ticket WEB y que no asegura su atención al momento de presentarse en Clínica Alemana. En caso de no asistir informaremos a la seremi de Salud de los Ríos.
                                    Este ticket corresponde a una prereserva para realizar trámites en el servicio (consultas, agendar y otros). En caso de dudas contactarnos a nuestra mesa central 63 224 6100.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" id="btn_confirmar" v-on:click.prevent="savereserva()" class="btn button-blue-alemana">Confirmar</button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modalConsultar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Consultar Reserva</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label id="rut_consulta" for="rut">{{ rut_consulta }}</label>
                            <input type="text" v-model="rut_buscar" name="rut" v-on:keyup="checkConsulta()" id="rut" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="idreserva">Numero Reserva</label>
                            <input type="text" name="idreserva" v-model="id_buscar" id="idreserva" class="form-control">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <a id="busc_consulta" :href="'/getreservapdf/' + servicio + '/' + rut_buscar + '/' + id_buscar" target="_blank" role="button" class="btn button-blue-alemana" hidden>Buscar</a>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="exitoPDF" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Hora Reservada</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <div>
                            Su reserva se ha ingresado correctamente, su numero de reserva es <span class="texto-blue-alemana">{{ id_exito }}</span>, descargue el comprobante para respaldo. <br>
                            Para anulaciones o consultas, llame al número <br>(+56) 63 224 6100.
                        </div>
                        <a :href="'/getreservapdf/' + servicio + '/' + rut_exito + '/' + id_exito" target="_blank" role="button" class="btn button-blue-alemana">Descargar PDF</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<style>
@media screen and (max-width: 700px) {
  #logo{
    margin-left: 10px;
    width: 100%;
}

.scroll-test{
    overflow-x: scroll;
}
.tableFixHead{
    height: 450px;
}
#tit{
    font-size: 19px;
}
}
@media screen and (min-width: 700px) and (max-width: 1500px) {
  #logo{
    margin-left: 10%;
    width: 100%;
}

.scroll-test{
    overflow-y: none;
}
.tableFixHead{
    height: 100px;
}
#tit{
    font-size: 20px;
}
}

@media screen and (min-width: 1500px) {
 #logo{
margin-left: 20%;
width: 100%;
}
#tit{
    font-size: 20px;
}
.tableFixHead{
    height: 100px;
}
}

a:link, a:visited, a:active a:hover{
    color: black;
    text-decoration:none;
}
.pointer-table{
    cursor: pointer;
}
.bootstrap-datetimepicker-widget tr:hover {
    background-color: #808080;
}
.text-small{
    font-size: 10px;
}
.color-table-alemana{
    background-color: #407EC9 ;
    color: white;
}
.celda-reservado{
    background-color: #00A499;
    color: white;
}
.button-blue-alemana{
    background-color: #407EC9 !important;
    color: white !important;
}
.texto-blue-alemana{
    color: #407EC9;
}
.toast-success {
    background-color: #407EC9 !important;
}
.alert-alemana{
    background-color: #407EC9 !important;
}
.no-cupo{
    background-color: #cd6269;
    color: white;
}
.disp-cupo{
    background-color: #00A499;
    color: white;
}
.color-red-alemana{
    background-color: #797979;
    color: white;
}
.bloq-cupo{
    background-color: #d2d2d2;
}
.upper {
    text-transform: uppercase;
}
.tableFixHead thead th{
    position: sticky;
    top: 0;
}
.spinner-alemana{
    color:  #00A499 !important;
}
</style>

<script>
import axios from 'axios'
import toastr from 'toastr'
import LogoClinica from '@/Jetstream/LogoClinica'

export default {
    props: {
        servicio: Number
    },
    components: {
        LogoClinica,
    },
    data: function(){
        return {
            txt_rut:'RUT',
            txt_celular:'Celular',
            rut_consulta:'RUT Paciente',
            numfilas: 720,
            start_date: '',
            selectrow: '',
            selectcol: '',
            selectweek: '',
            diasemana: '',
            interval: '',
            rut: '',
            rut_exito: '',
            id_exito: '',
            name: '',
            email: '',
            address: '',
            telephone: '',
            datefilter_start: '',
            datefilter_end: '',
            hora_modal: '',
            fecha_modal: '',
            total_boxes: '',
            pacientes_count: '',
            id_buscar: '',
            rut_buscar: '',
            close_lu: false,
            close_ma: false,
            close_mi: false,
            close_ju: false,
            close_vi: false,
            close_sa: false,
            week_s: '',
            week_e: '',
            loading_table: false,
            dataschedule: [],
            listschedule: [],
            datapaciente: [],
            datadefaultpage: [],
            feriado_semana: [],
            semanabloq: [],
            rangosemanalab: [],
            errors: []
        }
    },
    async created(){
        var urlsemana = '/getsemana';
        await axios.get(urlsemana).then(response => {
            this.week_s = response.data.comienzo;
            this.week_e = response.data.fin;
        });
        var urlsemanabloq = '/getsemanabloq/' + this.servicio;
        await axios.get(urlsemanabloq).then(response => {
            this.semanabloq = response.data
        });
        var urlrangosemana = '/getrangosemanalaboral/' + this.servicio;
        await axios.get(urlrangosemana).then(response => {
            this.rangosemanalab = response.data
        });
        this.datefilter_start = moment().startOf('week').isoWeekday(this.week_s).format('YYYY-MM-DD'); // bloquea primer dia de semana habil
        this.datefilter_end = moment().endOf('week').isoWeekday(this.week_e).format('YYYY-MM-DD'); // bloquea desde la semana 3 en adelante (2 semanas posibles de edicion)
        this.selectweek = moment().format('YYYY-MM-DD');
        if(this.selectweek < this.datefilter_start){
            this.selectweek = this.datefilter_start;
        }
        if(moment(this.selectweek).format('d') == 0){
            this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
        }
        this.diasemana = moment().startOf('week').isoWeekday(8).format('DD-MM-YYYY') + ' hasta ' + moment().endOf('week').isoWeekday(6).format('DD-MM-YYYY');
        var urlstart = '/getschedule/' + this.servicio + '/' + moment().startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment().endOf('week').isoWeekday(6).format('YYYY-MM-DD');
        var urldefaultlayout = '/getdefaultlayout/' + this.servicio + '/' + moment().startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment().endOf('week').isoWeekday(6).format('YYYY-MM-DD');
        this.getschedule(urlstart, urldefaultlayout);
    },
    methods:{
        async getschedule(urlschedule, urllayout){
            this.close_lu = false;
            this.close_ma = false;
            this.close_mi = false;
            this.close_ju = false;
            this.close_vi = false;
            this.close_sa = false;
            await axios.get(urllayout).then(response => {
                this.datadefaultpage = response.data
            });
            this.start_date = this.datadefaultpage.start_block;
            this.interval = this.datadefaultpage.interval;
            this.dataschedule = [];
            //this.start_date = this.datadefaultpage.start_block;
            var arraydata = {};
            var data = { id:'',value:'',row:'',col:'',exist:false,bloqmodal:false,color:'' };
            await axios.get(urlschedule).then(response => {
                    this.listschedule = response.data.schedule;
                    this.total_boxes = response.data.boxes;
                    this.feriado_semana = response.data.feriados;
            });
            for(var i = 1; i <= this.numfilas; i++){
                arraydata = { time:'', mo:'', tu:'', we:'', th:'', fr:'', sa:'',row:'' };
                arraydata.time = moment(this.start_date,'HH:mm').format('HH:mm');
                this.start_date = moment(this.start_date,'HH:mm').add(this.interval,'m');
                for(var j = 0; j < 7; j++){
                    this.listschedule.forEach(info => {
                        if(info.row == i && info.column == j){
                            if(info.total < this.total_boxes){
                                //data = { id:1,value:('Boxs Reservados '+ info.total + ' de ' + this.total_boxes),row:i,col:j,exist:true,bloqmodal:false,color:'disp-cupo' };
                                data = { id:1,value:'',row:i,col:j,exist:true,bloqmodal:false,color:'disp-cupo' };
                            }
                            else{
                                data = { id:1,value:'',row:i,col:j,exist:true,bloqmodal:true,color:'no-cupo' };
                                //data = { id:1,value:('Boxs Reservados '+ info.total + ' de ' + this.total_boxes),row:i,col:j,exist:true,bloqmodal:true,color:'no-cupo' };
                            }
                        }
                    });
                    this.rangosemanalab.forEach(lab => {
                        if(lab.dia_semana == j && moment(lab.hora_maxima,'HH:mm') <= moment(arraydata.time,'HH:mm')){
                            data = { id:1,value:'',row:i,col:j,exist:true,bloqmodal:true,color:'bloq-cupo' };
                        }
                    });
                    if(j == 1){ arraydata.mo = data; }
                    if(j == 2){ arraydata.tu = data; }
                    if(j == 3){ arraydata.we = data; }
                    if(j == 4){ arraydata.th = data; }
                    if(j == 5){ arraydata.fr = data; }
                    if(j == 6){ arraydata.sa = data; }
                    data = { id:'',value:'',row:i,col:j+1,exist:false,bloqmodal:false,color:'' };
                }
                arraydata.row = i;
                this.dataschedule.push(arraydata);
                if(moment(this.start_date,'HH:mm') > moment('20:00','HH:mm')){
                    break;
                }
            }
            this.feriado_semana.forEach(fer => {
                if(fer.dia_semana == 1){ this.close_lu = true; }
                if(fer.dia_semana == 2){ this.close_ma = true; }
                if(fer.dia_semana == 3){ this.close_mi = true; }
                if(fer.dia_semana == 4){ this.close_ju = true; }
                if(fer.dia_semana == 5){ this.close_vi = true; }
                if(fer.dia_semana == 6){ this.close_sa = true; }
            });
            this.semanabloq.forEach(bloq => {
                if(bloq.dia_semana == 1){ this.close_lu = true; }
                if(bloq.dia_semana == 2){ this.close_ma = true; }
                if(bloq.dia_semana == 3){ this.close_mi = true; }
                if(bloq.dia_semana == 4){ this.close_ju = true; }
                if(bloq.dia_semana == 5){ this.close_vi = true; }
                if(bloq.dia_semana == 6){ this.close_sa = true; }
            });
            this.loading_table = true;
        },
        async showmodalagend(row, col, time, exist){
            this.errors = [];
            this.selectrow = row;
            this.selectcol = col;
            this.hora_modal = time;
            this.rut = '';
            this.name = '';
            this.address = '';
            this.telephone = '';
            this.email = '';
            this.fecha_modal = moment(this.selectweek).startOf('week').isoWeekday((8-1) + col).format('DD-MM-YYYY');
        },
        getday(){
            if(this.selectcol == 1){
                return 'LUNES';
            }
            if(this.selectcol == 2){
                return 'MARTES';
            }
            if(this.selectcol == 3){
                return 'MIERCOLES';
            }
            if(this.selectcol == 4){
                return 'JUEVES';
            }
            if(this.selectcol == 5){
                return 'VIERNES';
            }
            if(this.selectcol == 6){
                return 'SABADO';
            }
        },
        cambiarfecha(){
            if(moment(this.selectweek).format('d') == 0){
                this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
            }
            // bug IOS safari
            if(this.selectweek < this.datefilter_start){
                this.selectweek = this.datefilter_start;
            }
            if(this.selectweek > this.datefilter_end){
                this.selectweek = moment(this.datefilter_end,'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD');
            }
            // end bug
            this.diasemana = moment(this.selectweek).startOf('week').isoWeekday(8).format('DD-MM-YYYY') + ' hasta ' + moment(this.selectweek).endOf('week').isoWeekday(6).format('DD-MM-YYYY');
            var urlsearch = '/getschedule/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            var urllayout = '/getdefaultlayout/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            this.getschedule(urlsearch, urllayout);
        },
        showdiatitletable(day){
            return moment(this.selectweek).startOf('week').isoWeekday(day).format('DD-MM-YYYY');
        },
        formatofecha(fecha){
            return moment(fecha).format('DD-MM-YYYY');
        },
        formatohora(hora){
            return moment(hora,'HH:mm:ss').format('HH:mm');
        },
        addreserva(){
            this.errors = [];
            this.rut = '';
            this.name = '';
            this.address = '';
            this.telephone = '';
            this.email = '';
            $('#modalAgend').modal('hide');
        },
        async savereserva(){
            this.errors = [];
            var urlsavereserva = '/savereserva';
            await axios.post(urlsavereserva, {
                servicio: this.servicio,
                row: this.selectrow,
                column: this.selectcol,
                state: 1,
                fecha_reserva: moment(this.fecha_modal,'DD-MM-YYYY').format('YYYY-MM-DD'),
                hora_reserva: this.hora_modal,
                rut: this.rut.toUpperCase(),
                nombre: this.name.toUpperCase(),
                telefono: this.telephone,
                correo: this.email,
                direccion: this.address.toUpperCase()
            }).then(response => {
                if(moment(this.selectweek).format('d') == 0){
                    this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
                }
                var urlsearch = '/getschedule/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
                var urllayout = '/getdefaultlayout/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
                this.getschedule(urlsearch, urllayout);
                this.rut_exito = this.rut;
                this.id_exito = response.data.id;
                this.rut = '';
                this.name = '';
                this.address = '';
                this.telephone = '';
                this.email = '';
                if(this.id_exito == 0){
                    toastr.warning('No hay cupos disponibles para el bloque seleccionado.','Reserva');
                }
                else{
                    $('#modalAdd').modal('hide');
                    toastr.success('Reserva Ingresada.','Reserva');
                    $('#exitoPDF').modal('show');
                }
            }).catch(error => {
                for(var data in error.response.data.errors){
                    this.errors.push(error.response.data.errors[data].shift());
                }
            })
        },
        checkRut() {
            var valor = this.rut.replace('.','');
            valor = valor.replace('-','');
            let cuerpo = valor.slice(0,-1);
            let dv = valor.slice(-1).toUpperCase();
            this.rut = cuerpo + '-'+ dv
            if(cuerpo.length < 7) { this.txt_rut="RUT Incompleto";this.rut=valor; return false;}
            let suma = 0;
            let multiplo = 2;
            for(let i=1;i<=cuerpo.length;i++) {
                let index = multiplo * valor.charAt(cuerpo.length - i);
                suma = suma + index;
                if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
            }
            let dvEsperado = 11 - (suma % 11);
            dv = (dv == 'K')?10:dv;
            dv = (dv == 0)?11:dv;
            if(dvEsperado != dv) {this.txt_rut="RUT Incorrecto"; return false; }
        },
        checkRutConsulta() {
            var valor = this.rut_buscar.replace('.','');
            valor = valor.replace('-','');
            let cuerpo = valor.slice(0,-1);
            let dv = valor.slice(-1).toUpperCase();
            this.rut_buscar = cuerpo + '-'+ dv
            if(cuerpo.length < 7) { this.rut_consulta="RUT Incompleto";this.rut_buscar=valor; return false;}
            let suma = 0;
            let multiplo = 2;
            for(let i=1;i<=cuerpo.length;i++) {
                let index = multiplo * valor.charAt(cuerpo.length - i);
                suma = suma + index;
                if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
            }
            let dvEsperado = 11 - (suma % 11);
            dv = (dv == 'K')?10:dv;
            dv = (dv == 0)?11:dv;
            if(dvEsperado != dv) {this.rut_consulta="RUT Incorrecto"; return false; }
        },
        check() {
        if (this.checkRut()==false) {
            $("#in-rut").css("color", "#eb6608");
            $("#txt-rut").css("color", "#eb6608");
            $('#btn_confirmar').attr("disabled", true);
        }else {
            this.txt_rut="RUT";
            $("#in-rut").css("color", "rgb(74, 74, 74)");
            $("#txt-rut").css("color", "rgb(74, 74, 74)");
            $('#btn_confirmar').attr("disabled", false);
        }
        },
        isnumber(){
            var can = this.telephone.length; 
            if(can < 6 || can > 12){
                this.txt_celular="Celular Incorrecto";
                $("#in-celular").css("color", "#eb6608");
                $("#txt-celular").css("color", "#eb6608");
                $('#btn_confirmar').attr("disabled", true);
            }else{
                if(isNaN(this.telephone) || this.telephone == null){
                this.txt_celular="Celular debe contener solo números";
                $("#in-celular").css("color", "#eb6608");
                $("#txt-celular").css("color", "#eb6608");
                $('#btn_confirmar').attr("disabled", true);
                }else{
                    this.txt_celular="Celular";
                    $("#in-celular").css("color", "rgb(74, 74, 74)");
                    $("#txt-celular").css("color", "rgb(74, 74, 74)");
                    $('#btn_confirmar').attr("disabled", false);
                }             
            }
        },
        checkConsulta() {
        if (this.checkRutConsulta()==false) {
            $("#rut_consulta").css("color", "#eb6608");
            $("#rut").css("color", "#eb6608");
            $('#busc_consulta').attr("hidden", true);
        }else {
            this.rut_consulta="RUT Paciente";
            $("#rut_consulta").css("color", "rgb(74, 74, 74)");
            $("#rut").css("color", "rgb(74, 74, 74)"); 
            $('#busc_consulta').attr("hidden", false);
        }
        },
        borrar(){
            this.rut_consulta="RUT Paciente";
            $("#rut_consulta").css("color", "rgb(74, 74, 74)");
            $("#rut").css("color", "rgb(74, 74, 74)");
            this.rut_buscar ='';
            this.id_buscar ='';
        },
        year(){
            let year = moment().format('YYYY');
            return year;
        }   
    }
}
</script>